import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { request } from '#api';
import Form, { FormSection, InputMultiline, File, Select } from '#Form';
import Dialog from '#Dialog';
import { DisplayDateTime, DisplayUser } from '#Display';
import DialogTitle from './DialogTitle';

const SelectAction = ({ availableActions, actionsDef }) => {
	const actionOptions = useMemo(() =>
		actionsDef
			.filter(def => availableActions?.includes(def.tag))
			.map(def => ({
				value: def.tag,
				icon: def.icon,
				label: def.label,
			})),
		[ availableActions ],
	)

	return (
		<Select label="Action" name="actionTag" options={actionOptions} emptyLabel="-- choose an available action --" required />
	)
}

const TimelinePost = ({ _id, subjectId, availableActions, handleSubmit, actionsDef }) => {
	const [ data, setData ] = useState({})
	// const [ isRO, setRO ] = useState(true)

	const isRO = useMemo(() =>
		Boolean(_id),
		[ _id ]
	)

	useEffect(() => {
		// setRO(Boolean(_id))
		_id && request('/cabinet-exchange/timeline/get', { _id }).then(setData)
	}, [ _id ])

	const onSubmit = async () => {
		await request('/cabinet-exchange/timeline/post', { ...data, subjectId })
		setData({})
		handleSubmit?.()
	}

	const formProps = {
		_id,
		data, setData,
		onSubmit: !isRO && onSubmit,
		submitLabel: 'Submit',
		submitIcon: <PublishedWithChangesIcon />,
	}

	const title = useMemo(() => {
		if(!isRO)
			return 'New post'
		return (
			<Typography variant='h6' color="primary">
				<DisplayDateTime ts={data?.publishTS} /> - <DisplayUser uid={data?.author} />
			</Typography>
		)
	}, [ isRO, data ])

	return (
		<Form {...formProps}>
			<FormSection title={title}>
				{ Boolean(!isRO && availableActions?.length) && (
					<SelectAction actionsDef={actionsDef} availableActions={availableActions} />
				)}
				{ Boolean(isRO && data?.action?.tag) && (
					<Box sx={{ mb:2 }}>
						<Typography component="span">
							Action:
						</Typography>
						<Typography component="span" sx={{ ml:1, mr:1, verticalAlign:'middle' }}>
							{actionsDef.find(def => def.tag === data.action.tag)?.icon}
						</Typography>
						<Typography component="span">
							{actionsDef.find(def => def.tag === data.action.tag)?.label}
						</Typography>
					</Box>
				) }

				{ Boolean(!isRO || data?.message) && (
					<InputMultiline label="Message" name="message" disabled={isRO} />
				) }

				{ Boolean(!isRO || data?.attachments?.length) && (
					<File
						label="Attachments"
						name="attachments"
						uploadEndpoint="/cabinet-exchange/attachment/add"
						getEndpoint="/cabinet-exchange/attachment/get"
						downloadEndpoint="/cabinet-exchange/attachment/download"
						disabled={isRO}
					/>
				) }
			</FormSection>
		</Form>
	)
}

export const TimelineDialog = ({ _id, title, handleClose, actionsDef, readonly, availableActions }) => {
	const [ posts, setPosts ] = useState({})

	const handleRefresh = () => _id ? request('/cabinet-exchange/timeline/scan', { subjectId:_id }).then(setPosts) : setPosts({})
	useEffect(() => {
		handleRefresh()
	}, [ _id ])

	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={
				<DialogTitle
					itemTitle={title}
					contextTitle="Timeline"
				/>
			}
			width="lg"
		>
			{ Boolean(posts?.count) ?
				posts?.items.map(post => (
					<TimelinePost key={post} _id={post} subjectId={_id} handleSubmit={handleRefresh} actionsDef={actionsDef} />
				)).reverse() : (
					<Box sx={{ m:3 }}>
						<Typography variant='body2'>No posts found related to this resource</Typography>
					</Box>
				)
			}
		</Dialog>
	)
}

export const ActionDialog = ({ _id, title, item, handleClose, actionsDef }) => {
	const open = useMemo(() =>
		Boolean(_id),
	[ _id ])

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={
				<DialogTitle
					itemTitle={title}
					contextTitle="Trigger new action"
				/>
			}
			width="lg"
		>
			<TimelinePost subjectId={_id} availableActions={item?.actions || []} handleSubmit={handleClose} actionsDef={actionsDef} />
		</Dialog>
	)
}
