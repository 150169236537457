import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { request, requestDownload, useAuth } from '#api';
import { displayDate, DisplayUser } from '#Display';
import Fab from '#Fab';
import Data from '#Data';
import { TplWSidebar as Tpl } from '#Template'
import Button from '#Button';
import Search, { SearchText } from '#Search'
import ButtonPad from '#ButtonPad';

import ActionsLog from '../ActionsLog'
import { ActionDialog, TimelineDialog } from '../Timeline';
import { statuses, actionsDef, producerOptions, item2title } from './schemas'
import { RequestDialog } from './EditForm';
import { SparepartsDialog } from './SparepartsForm';

const useActions = ({ handleRefresh, setRequestHistory, setEdit, setTimeline, setSpareparts, setActionDialog }) => {
	const auth = useAuth()

	return ({ _id, row, handleRowRefresh }) => {
		const itemTitle = item2title(row)

		const handleActionsLog = async ({ _id, row }) => {
			const events = await request('/cabinet-exchange/action/log', { _id })
			setRequestHistory({ events, itemTitle })
		}

		const actions = [
			{
				label: "View",
				icon: <PreviewIcon />,
				onClick: () => setEdit({
					_id,
					row,
					itemTitle,
					open: true,
				}),
			},
			{
				label: "Log",
				icon: <ManageSearchIcon />,
				onClick: () => handleActionsLog({ _id, row }),
			},
			{
				label: "Timeline",
				icon: <ViewTimelineIcon />,
				onClick: () => setTimeline({
					_id,
					row,
					itemTitle,
					readonly: !auth.check('cabinetexchange-list'),
					availableActions: row?.actions || [],
				}),
			},
		]
		if(row?.actions?.length)
			actions.push({
				label: "New action",
				icon: <PublishedWithChangesIcon />,
				onClick: () => setActionDialog({ _id, row, itemTitle }),
			})
		if(row?._state['service-management-approved'])
			actions.push({
				label: "Spare parts",
				icon: <EngineeringIcon />,
				onClick: () => setSpareparts({ _id, row, itemTitle }),
			})

		return (
			<ButtonPad def={actions} />
		)
	}
}

const RefNumber = ({ row }) => (
	<>
		<Typography variant='body2'>{row?.device?.serial}</Typography>
		<Typography variant='caption'>{producerOptions?.find(opt => opt.value === row?.device?.producer)?.label}</Typography>
	</>
)

const Customer = ({ row }) => (
	<>
		<Typography variant='body2'>{row?.customer?.name}</Typography>
		<Typography variant='caption'>{row?.store?.country}</Typography>
	</>
)

const Status = ({ row }) => (
	<Typography>
		{statuses?.find(status => status.value === row?.status)?.icon || null}
		{statuses?.find(status => status.value === row?.status)?.label || '???'}
	</Typography>
)

const tableDef = [
	{
		label: 'Num',
		content: ({ row }) => row?._registry.num,
	},
	{
		label: 'Registered on',
		content: ({ row }) => displayDate(row?._state.created),
	},
	{
		label: 'Reporting date',
		content: ({ row }) => row?.notificationToAHTDate?.split('-').reverse().join('/')
	},
	{ label:'Reference number', content:RefNumber },
	{ label:'Customer', content:Customer },
	{
		label: 'Product support',
		content: ({ row }) => row?.psApproveEvent?.author && <DisplayUser uid={row?.psApproveEvent?.author} />,
	},
	{ label:'Status', content:Status },
]

export default function CabinetExchangeList() {
	const [ scan, setScan ] = useState({})
	const [ edit, setEdit ] = useState(null)
	const [ timeline, setTimeline ] = useState(null)
	const [ spareparts, setSpareparts ] = useState(null)
	const [ actionDialog, setActionDialog ] = useState(null)
	const [ requestHistory, setRequestHistory ] = useState(null)
	const [ filterString, setFilterString ] = useState('')
	const auth = useAuth()

	const handleRefresh = () => request('/cabinet-exchange/list', { filterString }).then(setScan)

	useEffect(() => {
		handleRefresh()
	}, [ filterString ])

	const Actions = useActions({ handleRefresh, setRequestHistory, setEdit, setTimeline, setSpareparts, setActionDialog })
	const fetchRow = async (_id) => {
		const row = await request('/cabinet-exchange/get', { _id })
		const log = await request('/cabinet-exchange/action/log', { _id })
		return { ...row, log }
	}

	return (
		<Tpl title='Cabinet exchange requests'>
			<ActionsLog
				title={requestHistory?.itemTitle}
				events={requestHistory?.events}
				handleClose={() => setRequestHistory()}
				actionsDef={actionsDef}
			/>
			<RequestDialog
				_id={edit?._id}
				item={edit?.row}
				open={edit?.open}
				handleClose={() => {
					setEdit()
					handleRefresh()
				}}
			/>
			<TimelineDialog
				_id={timeline?._id}
				title={timeline?.itemTitle}
				item={timeline?.row}
				handleClose={() => setTimeline()}
				actionsDef={actionsDef}
				readonly={timeline?.readonly}
				availableActions={timeline?.availableActions}
			/>
			<ActionDialog
				_id={actionDialog?._id}
				title={actionDialog?.itemTitle}
				item={actionDialog?.row}
				handleClose={() => setActionDialog()}
				actionsDef={actionsDef}
			/>
			<SparepartsDialog
				_id={spareparts?._id}
				title={spareparts?.itemTitle}
				item={spareparts?.row}
				handleClose={() => setSpareparts()}
			/>

			<Search>
				<SearchText label="Cerca" name="filter-string" value={filterString} setValue={setFilterString} />
			</Search>

			<Box sx={{ textAlign:'right' }}>
				<Button
					label="Export CSV"
					icon={<FileDownloadIcon />}
					onClick={() => requestDownload('/cabinet-exchange/export/csv')}
				/>
			</Box>

			<Data
				def={tableDef}
				scan={scan}
				fetchRow={fetchRow}
				actions={Actions}
			/>
			{ auth.check('cabinetexchange-submit') && <Fab label="New request" onClick={() => setEdit({ open:true })} /> }
		</Tpl>
	)
}
