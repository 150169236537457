import React, { useMemo } from 'react';

const DialogTitle = ({ itemTitle, contextTitle }) =>
	useMemo(() => {
		const slices = []

		contextTitle && slices.push(contextTitle)
		itemTitle && slices.push(itemTitle)

		return slices.join(' - ')
	}, [ itemTitle, contextTitle ])

export default DialogTitle