import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useAuth } from '#api';

import CabinetExchangeCreatePage from './Create'
import CabinetExchangeListPage from './List'

const CabinetExchangeRouter = () => {
	const auth = useAuth()

	const aclList = auth.check('cabinetexchange-list')
	const aclSubmit = auth.check('cabinetexchange-submit')

	return (
		<Routes>
			<Route path="/:_id" element={<CabinetExchangeCreatePage />} />

			{ !aclList && aclSubmit && (
				<Route path="/" element={<CabinetExchangeCreatePage />} />
			)}
			{ aclList && (
				<Route path="/" element={<CabinetExchangeListPage />} />
			)}
		</Routes>
	)
}

export default CabinetExchangeRouter