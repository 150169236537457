import React, { useMemo } from 'react'
import Dialog from '#Dialog'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// import { actionsDef } from './schemas'
import DialogTitle from './DialogTitle';

const ActionsLog = ({ events, handleClose, actionsDef, title }) => {
	// const open = useMemo(
	// 	() => Boolean(_id),
	// 	[ _id ],
	// )
	const open = useMemo(() => Array.isArray(events), [ events ])

	// const logItems = useMemo(
	// 	() => item?.log?.items || [],
	// 	[ item ],
	// )

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={
				<DialogTitle
					itemTitle={title}
					contextTitle="Action log"
				/>
			}
			width="lg"
		>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontWeight:'bold' }}>When</TableCell>
							<TableCell sx={{ fontWeight:'bold' }}>Who</TableCell>
							<TableCell sx={{ fontWeight:'bold' }}>Action triggered</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{ Boolean(events?.length) && events.map((event, logIdx) => (
						<TableRow key={logIdx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell>{ new Date(event?.publishTS).toString() }</TableCell>
							<TableCell>{event.author || '-'}</TableCell>
							<TableCell>
								<Box sx={{ verticalAlign:'middle', display:'inline', mr:1 }}>
									{actionsDef.find(actionDef => actionDef.tag === event.action.tag)?.icon} 
								</Box>
								{actionsDef.find(actionDef => actionDef.tag === event.action.tag)?.label}
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
		</Dialog>
	)
}
export default ActionsLog
