import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import FabMui from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom'
import AddIcon from '@mui/icons-material/Add';

// import { TplConfCtx } from './template/Template'

const fabStyle = {
	position: 'fixed',
	bottom: 16,
	right: 16,
}

export default function Fab({ label, icon, linkTo, onClick }) {
	// const tplConf = useContext(TplConfCtx)
	const props = { icon, onClick }
	if(linkTo) {
		props.component = Link
		props.to = linkTo
	}

	const content = (
		<FabMui color="primary" {...props}>
			<AddIcon />
		</FabMui>
	)
	const block = label ? <Tooltip title={label} placement="left">{content}</Tooltip> : content

	return (
		<Box sx={fabStyle}>
			{block}
		</Box>
	)
	// return (
	// 	<Zoom sx={fabStyle} in={tplConf.scrollPosition<=0}>{block}</Zoom>
	// )
}
