import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import { faArrowLeft, faRightToBracket, faRightFromBracket, faBars } from '@fortawesome/pro-solid-svg-icons'
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

// import APICtx from './api'
// import { TplConfCtx } from './Template'
// import { useAuth } from '../api';
import { Tooltip } from '@mui/material';
import { useAuth, setAuthToken } from '../api';

// const MenuItem = def => (
// 	<Button color="inherit" {...def} sx={{ ml:1 }} />
// )

const LogoutBtn = () => (
	<Tooltip title="Logout">
		<IconButton
			color="inherit"
			edge="end"
			onClick={() => setAuthToken()}
		>
			<LogoutIcon />
		</IconButton>
	</Tooltip>
)

export default function HeadBar({ title, sidebarWidth = 0, toolbarHeight, sidebarOpen, toggleSidebar }) {
	const auth = useAuth()
	// const tplConf = useContext(TplConfCtx)

	// const { tools, toolAuth, backTo } = tplConf
	const appbarSX = {
		width: {
			md: `calc(100% - ${sidebarWidth})`,
		}
	}
	const menuBtnSX = {
		mr: 2,
		display: { xs:'block', md:'none' },
	}

	return (
		<AppBar position="fixed" sx={appbarSX} open={sidebarOpen}>
			<Toolbar>
				<IconButton aria-label="delete" color="inherit" sx={menuBtnSX} onClick={toggleSidebar?.(true)}>
					<MenuIcon />
				</IconButton>
				{/* { backTo && <Button icon={faArrowLeft} color="inherit" sx={{ mr:2 }} linkTo={backTo} /> } */}
				<Typography variant="h6" component="div" sx={{ flexGrow:1, overflow:'hidden', whiteSpace:'nowrap' }}>
					{title}
				</Typography>
				{ auth.check() && <LogoutBtn /> }
			</Toolbar>
		</AppBar>
	)
}
