import React, { useMemo, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCardIcon from '@mui/icons-material/AddCard';
import Screen, { TplWSidebar as Tpl } from '#Template'
import { request, setAuthToken, useAuth } from '#api';

import logoImg from './logo.png'

import CabinetExchangeRouter from './pages/cabinetExchange/Router'

const Router = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	// useEffect(() => {
	// 	if(auth.check('request-view') && location.pathname === '/')
	// 		navigate('/request/list')
	// 	if(!auth.check('request-view') && auth.check('request-create') && location.pathname !== '/request/create')
	// 		navigate('/request/create')
	// }, [ auth, location, navigate ])

	return (
		<Routes>
			<Route path="cabinet-exchange/*" element={<CabinetExchangeRouter />} />

			<Route path="/" element={<Blank />} />
		</Routes>
	)
}

const Blank = () => (
	<Tpl />
)

const useMenu = (api) => {
	const auth = useAuth()
	return useMemo(() => {
		const menu = []

		// if(auth.check('cabinetexchange-submit'))
		// 	menu.push({
		// 		label: "New request",
		// 		linkTo: "/cabinet-exchange/create",
		// 		icon: <AddCardIcon />,
		// 	})
		if(auth.check('cabinetexchange-list'))
			menu.push({
				label: "Cabinet exchange",
				linkTo: "/cabinet-exchange",
				icon: <FormatListBulletedIcon />,
			})

		return menu
	}, [ auth ])
}

const Autologin = ({ children }) => {
	const location = useLocation()

	const handleAutologin = async token => {
		const session = await request('/auth/autologin/evaluate', { token }).catch(() => null)
		session && setAuthToken(session)
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const autoLoginToken = params.get('_autologin')
		autoLoginToken && handleAutologin(autoLoginToken)
	}, [ location ])

	return children
}

export default function App() {
	const auth = useAuth()
	const menu = useMenu()

	return (
		<Screen logo={logoImg} menu={menu}>
			<Autologin>
				{ auth.check() && <Router /> }
			</Autologin>
		</Screen>
	)
}
